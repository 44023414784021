import styled from 'styled-components';
import bgImage from 'assets/icons/companies-bg.svg';
import { medias } from 'assets/styled/tokens';
export const PageBody = styled.div`
  width: 100%;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
`;
export const PageHeader = styled.header`
  width: 100%;
  height: 64px;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  top: 0;
  position: relative;
  .logo-desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
  @media (min-width: ${medias.desktopSm}) {
    .logo-desktop {
      display: block;
    }
    .mobile {
      display: none;
    }
  }
  svg {
    width: 154px;
    height: 24px;
    position: absolute;
    left: calc(33% - 154px);
  }
`;
export const MobileHeader = styled.div`
  font-family: Blinker;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #ffffff;
  position: absolute;
  left: calc(33% - 109px);
`;
export const PageFooter = styled.footer`
  width: 100%;
  height: 120px;
  background-color: #1e1e1e;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 102px;
    height: 16px;
  }
  div {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #888888;
    margin-left: 10px;
  }
`;
export const PageMain = styled.main`
  //height: 100%;
  top: 64px;
`;
export const MainTitleSection = styled.section`
  background: url(${bgImage}) no-repeat center center;
  background-color: #f5f3f1;
  background-size: cover;
  width: 100vw;
  height: 372px;
  display: flex;
  align-items: center;
  position: relative;
`;
export const MainContentSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MainContent = styled.div`
  margin: 88px 15px;
  height: 100%;
  min-width: 327px;

  box-shadow: 0px 4px 24px 0px #00000026;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (min-width: ${medias.desktopSm}) {
    width: 800px;
  }
`;
export const ContentImage = styled.img`
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 450px;
  border: none;
`;
export const ContentInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  margin: 20px 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
  @media (min-width: ${medias.desktopSm}) {
    margin: 20px 100px;
  }
`;
export const ContentAdress = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: left;
  margin: 20px 16px;

  @media (min-width: ${medias.desktopSm}) {
    margin: 20px 100px;
  }
`;
export const InfoTitle = styled.h2`
  font-family: Blinker;
  font-size: 32px;
  font-weight: 700;
  line-height: 36px;
  text-align: left;
  color: #1e1e1e;
  margin-top: 25px;
  &:last-of-type {
    margin-top: 35px;
  }
`;
export const InfoParagraph = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #888888;
  margin-bottom: 30px;
  margin-top: 15px;
`;
export const HeaderContentContainer = styled.div`
  width: 245px;
  height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  left: calc(33% - 109px);
  @media (min-width: ${medias.desktopSm}) {
    position: absolute;
    left: calc(33% - 245px);
  }
`;
export const HeaderTitle = styled.h1`
  font-family: Blinker;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  text-align: left;
  color: #1e1e1e;
`;
export const HeaderImage = styled.img`
  width: 100%;
  height: 144px;
  border-radius: 8px;
`;
export const PlaceholderImage = styled.img`
  height: 144px;
  width: 144px;
  border-radius: 8px;
`;
export const AdressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;

  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: #888888;
  padding: 5px 0;
  &:first-of-type {
    align-items: flex-start;
  }
  align-items: center;
  b {
    font-weight: 700;
    color: #1e1e1e;
    //margin-left: 10px;
    margin-right: 5px;
  }
  .local-icon {
    margin-top: 5px;
  }
  svg {
    &:first-of-type {
      width: 26px;
    }
    width: 16px;
    height: 16px;
    margin-right: 10px;
  }
  @media (min-width: ${medias.desktopSm}) {
    &:first-of-type {
      align-items: center;
    }
    .local-icon {
      margin-top: 0;
    }
  }
`;
export const LinkSite = styled.a`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #dc7c00;
  text-decoration: underline;
`;
export const SocialMediaContainer = styled.div`
  padding: 10px 0;
  svg {
    margin: 0 15px 0 0;
    width: 48px;
    height: 48px;
  }
`;
export const LetsTalkContent = styled.div`
  min-width: 327px;
  height: 100%;
  margin: 0 15px 88px 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: ${medias.desktopSm}) {
    width: 800px;
  }
`;
export const LetsTalkTitle = styled.h3`
  font-family: Blinker;
  font-size: 48px;
  font-weight: 700;
  line-height: 52px;
  text-align: center;
  color: #1e1e1e;
`;
export const LetsTalkInfo = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #888888;
  padding: 10px 15px;
  @media (min-width: ${medias.desktopSm}) {
    padding: 10px 70px;
  }
`;
export const WhatsappButton = styled.a`
  height: 64px;
  width: 327px;
  text-decoration: none;
  background-color: #0cac3c;
  border-radius: 4px;
  color: #ffffff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 18.75px;
  letter-spacing: 0.5px;
  text-align: left;
  padding: 10px 15px;
  margin: 20px 15px 0 15px;
  @media (min-width: ${medias.desktopSm}) {
    padding: 0;
    margin-top: 20px 0 0 0;
    width: 385px;
  }
  svg {
    margin-right: 10px;
  }
`;

export const ErrorPage = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #f5f3f1;
  svg {
    width: 110px;
    height: 120px;
  }
  h1 {
    font-family: Blinker;
    font-size: 32px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0.5px;
    text-align: center;
    color: #1e1e1e;
    margin: 25px 0;
  }
  a {
    border: 1px solid #e5e5e5;
    width: 185px;
    height: 40px;
    background-color: #ffffff;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.5px;
    text-align: left;
    color: #1e1e1e;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
export const EmailContainer = styled.div`
  width: 327px;
  margin: 25px 15px 0 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #888888;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;

  svg {
    margin-right: 5px;
  }
  @media (min-width: ${medias.desktopSm}) {
    width: 800px;
  }
`;
