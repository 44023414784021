import { useParams } from 'react-router-dom';
import {
  AdressContainer,
  ContentAdress,
  ContentImage,
  ContentInfo,
  HeaderContentContainer,
  HeaderImage,
  HeaderTitle,
  InfoParagraph,
  InfoTitle,
  LetsTalkContent,
  LetsTalkInfo,
  LetsTalkTitle,
  LinkSite,
  MainContent,
  MainContentSection,
  MainTitleSection,
  PageBody,
  PageFooter,
  PageHeader,
  PageMain,
  PlaceholderImage,
  SocialMediaContainer,
  WhatsappButton,
  MobileHeader,
  ErrorPage,
  EmailContainer,
} from './styled';
import { ReactComponent as LkpLogo } from 'assets/icons/lkp-logo-wt.svg';
import { ReactComponent as LocalIcon } from 'assets/icons/local-icon.svg';
import { ReactComponent as PhoneIcon } from 'assets/icons/phone-icon.svg';
import { ReactComponent as WebIcon } from 'assets/icons/web-icon.svg';
import { ReactComponent as IgIcon } from 'assets/icons/IG-icon.svg';
import { ReactComponent as LkIcon } from 'assets/icons/lkd-icon.svg';
import { ReactComponent as FbIcon } from 'assets/icons/fb-icon.svg';
import { ReactComponent as WhatsappIcon } from 'assets/icons/whatsapp-wt.svg';
import { ReactComponent as WarningIcon } from 'assets/icons/warning-wt.svg';
import { ReactComponent as EmailIcon } from 'assets/icons/email-icon-o.svg';
import React, { useEffect, useState } from 'react';
import { RegistrationService } from 'services/main/endpoints/RegistrationService';
import lkpLogo from 'assets/images/lkp-logo.png';
export const CompaniesPage = () => {
  const [data, setData] = useState({});
  const [pageDontExist, setPageDontExist] = useState(false);
  const { subdominio } = useParams();
  const fetcData = async () => {
    try {
      const response = await RegistrationService.getCompanyPageData({ subdominio });
      setData(response.data);
    } catch (e) {
      if (e.response.status === 404) {
        setPageDontExist(true);
      }
    }
  };
  useEffect(() => {
    fetcData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subdominio]);
  const renderSection = (title, content, index) =>
    content.length > 0 && (
      <React.Fragment key={index}>
        <InfoTitle>{title}</InfoTitle>
        <InfoParagraph>{content}</InfoParagraph>
      </React.Fragment>
    );

  const sections = [
    { title: `História da ${data?.empresaObj?.nomeFantasia}`, content: data?.historia },
    { title: `Diferenciais da ${data?.empresaObj?.nomeFantasia}`, content: data?.diferencial },
    { title: 'Serviços Oferecidos', content: data?.servicos },
    { title: 'Público-Alvo', content: data?.publicoAlvo },
  ];

  const shouldRenderContent = sections.some(section => section?.content?.length > 0);
  const socialMediaLinks = [
    { href: data?.instagran, icon: <IgIcon />, name: 'Instagram' },
    { href: data?.linkedin, icon: <LkIcon />, name: 'LinkedIn' },
    { href: data?.facebook, icon: <FbIcon />, name: 'Facebook' },
  ];

  const shouldRenderSocialMedia = socialMediaLinks.some(social => social.href);
  const formatPhoneNumber = phoneNumber => {
    const numericPhoneNumber = phoneNumber?.replace(/\D/g, '');
    return `55${numericPhoneNumber}`;
  };
  const formattedPhoneNumber = formatPhoneNumber(data?.whatsapp);
  return (
    <PageBody>
      <PageHeader>
        <LkpLogo className="logo-desktop" />
        <MobileHeader className="mobile">Saiba mais</MobileHeader>
      </PageHeader>
      <PageMain>
        {pageDontExist ? (
          <ErrorPage>
            <WarningIcon />
            <h1>Ops, a página não foi encontrada.</h1>
            <a href="https://leankeep.com/" target="_blank">
              ACESSAR NOSSO SITE
            </a>
          </ErrorPage>
        ) : (
          <>
            <MainTitleSection>
              <HeaderContentContainer>
                {data?.empresaObj?.logomarcaPath?.length > 0 ? (
                  <HeaderImage
                    src={process.env.REACT_APP_URL_IMAGES + data?.empresaObj?.logomarcaPath}
                    alt={`Logomarca da ${data?.empresaObj?.nomeFantasia}`}
                  />
                ) : (
                  <PlaceholderImage src={lkpLogo} alt="placeholder image" />
                )}
                <HeaderTitle>{data?.empresaObj?.nomeFantasia}</HeaderTitle>
              </HeaderContentContainer>
            </MainTitleSection>
            <MainContentSection>
              <MainContent>
                {data?.fotoEdificio?.length > 0 ? (
                  <ContentImage src={process.env.REACT_APP_URL_IMAGES + data?.fotoEdificio} />
                ) : null}
                {shouldRenderContent && (
                  <ContentInfo>
                    {sections.map((section, index) => renderSection(section.title, section.content, index))}
                  </ContentInfo>
                )}
                <ContentAdress>
                  <InfoTitle>Contato</InfoTitle>

                  <AdressContainer>
                    <LocalIcon className="local-icon" />
                    <p>
                      <b>Endereço:</b>
                      {data?.endereco +
                        `, ` +
                        data?.numero?.replace(/\D/g, '') +
                        data?.complemento +
                        `, ` +
                        data?.cidadeObj?.nome +
                        ` - ` +
                        data?.cidadeObj?.estadoNome +
                        `, ` +
                        data?.cep}
                    </p>
                  </AdressContainer>
                  <AdressContainer>
                    <PhoneIcon />
                    <b>Telefone:</b>
                    {data?.telefone}
                  </AdressContainer>
                  {data?.url?.length > 0 && (
                    <AdressContainer>
                      <WebIcon />
                      <b>Site:</b>
                      <LinkSite href={data?.url} target="_blank">
                        {data?.url}
                      </LinkSite>
                    </AdressContainer>
                  )}
                  {shouldRenderSocialMedia && (
                    <div class="sp-tp">
                      <InfoTitle>Siga nossas redes</InfoTitle>
                      <SocialMediaContainer>
                        {socialMediaLinks.map(
                          (social, index) =>
                            social.href && (
                              <LinkSite key={index} href={social.href} target="_blank" aria-label={social.name}>
                                {social.icon}
                              </LinkSite>
                            ),
                        )}
                      </SocialMediaContainer>
                    </div>
                  )}
                </ContentAdress>
              </MainContent>
              <LetsTalkContent>
                <LetsTalkTitle>Vamos Conversar!</LetsTalkTitle>
                <LetsTalkInfo>
                  Tem dúvidas ou precisa de mais informações? Vamos conversar! Entre em contato conosco e nossa equipe
                  estará pronta para ajudar. Clique aqui para enviar uma mensagem.
                </LetsTalkInfo>
                <WhatsappButton
                  href={`https://wa.me/${formattedPhoneNumber}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <WhatsappIcon />
                  Entrar em contato pelo Whatsapp
                </WhatsappButton>
                <EmailContainer>
                  <EmailIcon />
                  {data?.email}
                </EmailContainer>
              </LetsTalkContent>
            </MainContentSection>
          </>
        )}
      </PageMain>
      <PageFooter>
        <LkpLogo />
        <div>Todos os direitos reservados.</div>
      </PageFooter>
    </PageBody>
  );
};
